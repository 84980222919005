import { NavigationModel } from '../models';

export const mainMenu: NavigationModel[] = [
  {
    type: 'buyer',
    label: `i'm a buyer`,
    url: '/',
  },
  {
    type: 'supplier',
    label: `i'm a supplier`,
    url: '/supplier',
  },
];
