import { NavigationModel } from '../models';

export const loginMenu: NavigationModel[] = [
  {
    label: 'log in as a buyer',
    url: 'https://my.print-marketplace.co.uk/?idp_hint=ccs',
  },
  {
    label: 'log in as a supplier',
    url: 'https://my.print-marketplace.co.uk',
  },
];
