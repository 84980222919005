import { CardContentModel } from '../models';

export const supplierHowItWorks: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/supplier/icon_circle_registration.svg',
    title: 'Register your interest and complete your application',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/supplier/icon_circle_specify.svg',
    title: 'Set up your manufacturing profile',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/supplier/icon_circle_gaining_customers.svg',
    title: 'Start providing quotes – instantly!',
  },
];
