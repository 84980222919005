import * as React from 'react';

// Components
import Benefits from '../components/benefits';
import Hero from '../components/hero';
import HighlightsSupplier from '../components/highlights-supplier';
import HowItWorks from '../components/how-it-works';
import NavbarComponent from '../components/navbar';
import SEO from '../components/seo';
import Footer from '../components/footer';
import BecomeSupplier from '../components/become-supplier';

// Data
import { footerContent } from '../data/footer';
import footerPartnersCCSContent from '../data/footer-partners-ccs';
import { mainMenu } from '../data/menu';
import { loginMenu } from '../data/menu-login';
import { supplierBenefits } from '../data/supplier-benefits';
import { supplierHowItWorks } from '../data/supplier-how-it-works';

// Layout
import Layout from '../layout/layout';

const SupplierPage = () => (
  <Layout>
    <SEO
      title="Supplier - Win more business from the public sector | Print Marketplace"
      description="The simple way to save on public sector printing. Compare printing prices from trusted suppliers quickly and simply."
    />
    <section
      className="container-fluid home-hero-bg pb-3 pb-md-0"
      style={{
        backgroundImage:
          'url("https://cdn-dev.print-marketplace.co.uk/frontend/home-hero-image.png")',
      }}
    >
      <div className="row">
        <div className="col-xl-12 col-md-12 mx-auto">
          <NavbarComponent
            current="buyer"
            mainMenu={mainMenu}
            loginMenu={loginMenu}
            logo="https://cdn-dev.print-marketplace.co.uk/frontend/pmp-ccs-logo.svg"
            logoLink="/"
          />
        </div>
      </div>
      <div className="container">
        <Hero
          jumbotron="Win more business from the public sector through Print Marketplace"
          lead="Increase your sales pipeline by accessing hundreds of public sector opportunities
          through our unique online order and pricing platform"
          hasVideo={false}
          hasRegisterNow
          hasRequestADemo={false}
          hasCreateAccount={false}
        />
      </div>
    </section>

    <section className="container-fluid pb-3">
      <div className="container home-hero-lift">
        <Benefits benefits={supplierBenefits} />
      </div>
    </section>

    <section className="container-fluid pb-6">
      <HowItWorks
        howItWorks={supplierHowItWorks}
        intro="Simple, free and easy to use – gives you access to hundreds of print buyers across the UK's public sector"
        hasRegisterNow
        hasRequestADemo={false}
        hasSeeHowItWorks={false}
        video="https://player.vimeo.com/video/484398245?autoplay=1&loop=1&autopause=0"
        theme="primary"
      />
    </section>

    <section className="container-fluid bg--white pb-6">
      <div className="container">
        <HighlightsSupplier />

        <BecomeSupplier />
      </div>
    </section>

    <Footer
      sections={footerContent}
      partners={footerPartnersCCSContent}
      year={`${new Date().getFullYear()}`}
    />
  </Layout>
);

export default SupplierPage;
