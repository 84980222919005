import React from 'react';

const BecomeSupplier = () => (
  <section className="row text-center pt-6 pb-3">
    <div className="col mb-4">
      <h2>Become a Print Marketplace supplier</h2>
      <a
        href="https://register.print-marketplace.co.uk"
        aria-label="Register"
        className="btn btn-primary bg--accent mt-3"
      >
        <span className="align-middle">register now</span>
      </a>
    </div>
  </section>
);

export default BecomeSupplier;
