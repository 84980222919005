import { CardContentModel } from '../models';

export const supplierBenefits: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/supplier/icon_big_opportunities.svg',
    title: 'Access a wide range of public sector opportunities',
    description:
      'Increase the geographical spread and customer base of your business',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/supplier/icon_big_free_simple.svg',
    title: 'Free and simple to use',
    description:
      'Promote your business with minimum investment - set up your profile and you remain in control of your pricing',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/supplier/icon_big_payment_terms.svg',
    title: 'Attractive payment terms',
    description:
      'Print Marketplace is built with commercial services in mind - attractive payment terms within 30 days',
  },
];
