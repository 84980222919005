import React from 'react';

const HighlightsSupplier = () => (
  <div>
    <header className="row text-center pt-6 pb-3">
      <div className="col">
        <h2>Print Marketplace highlights</h2>
      </div>
    </header>

    <section className="row bg--gray-light px-5 py-5 pb-lg-0 my-4 rounded align-items-center">
      <div className="col-md-6 pb-4 pb-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/supplier/market_conditions.svg"
          alt="Complete control of pricing at any moment"
          className="img-fluid"
        />
      </div>
      <div className="col-md-6">
        <h3 className="h4">Complete control of pricing at any moment</h3>

        <ul>
          <li>You set your prices to suit market conditions or capacity</li>
          <li>Alter as much as you require</li>
          <li>Manage your margins</li>
        </ul>
      </div>
    </section>

    <section className="row bg--gray-light p-5 rounded align-items-center">
      <div className="col-md-6 col-lg-5 offset-lg-1 offset-md-0">
        <h3 className="h4">Easy to use online invoicing tool</h3>

        <ul>
          <li>Share invoices quickly and easily</li>
          <li>Simple PDF upload</li>
          <li>Increased visibility of invoice status</li>
        </ul>
      </div>
      <div className="col-md-6 col-lg-6 pt-4 pt-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/supplier/invoicing_tool.svg"
          alt="Easy to use online invoicing tool"
          className="img-fluid"
        />
      </div>
    </section>

    <section className="row bg--gray-light px-5 py-5 pb-lg-0 my-4 rounded align-items-center">
      <div className="col-md-6 pb-4 pb-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/supplier/dashboard.svg"
          alt="Retained order history"
          className="img-fluid"
        />
      </div>
      <div className="col-md-6">
        <h3 className="h4">
          Analytics dashboard provides performance and pricing competitiveness
          reports
        </h3>

        <ul>
          <li>Set your prices more accurately</li>
          <li>Manage and monitor your effectiveness</li>
          <li>Gain insight on pricing performance</li>
        </ul>
      </div>
    </section>
  </div>
);

export default HighlightsSupplier;
